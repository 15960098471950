@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Inter";

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  /* font-family: "Public Sans", sans-serif; */
  /* font-family: 'DM Serif Text', serif; */
  /* font-family: 'Poppins', sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, "Inter";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 100vh;
  width: 100%;
}
a {
  text-decoration: none;
  color: "inherit";
}

.infinite-scroll-component__outerdiv {
  width: 100%;
  /* ...whatever more styles you want */
}
::-webkit-scrollbar {
  width: 8.5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c42524 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #c42524 !important;
}
